/* */

.pagination-box {
    color: var(--body-color);
}

.pagination-box .pagination {
    gap: 1rem !important;
}

/* @media (min-width: 375px){

    .pagination-box .pagination {
        gap: 1.5rem !important;
    }
}

@media (min-width: 390px){

    .pagination-box .pagination {
        gap: 2rem !important;
    }
}

@media (min-width: 400px){

    .pagination-box .pagination {
        gap: 2.3rem !important;
    }
}

@media (min-width: 420px){

    .pagination-box .pagination {
        gap: 2.5rem !important;
    }
} */

.pagination-box .pagination-pages-info {
    gap: 0.5rem !important;
}

/* Buttons */

.pagination-box .pagination .btn-control {
    width: 50px;
    height: 50px;
    padding: 0 !important;
    align-items: center !important;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    background: var(--ltn__secondary-color);
}

/* .pagination-box .pagination .btn-control {
    flex-flow: row nowrap !important;
    align-items: center !important;
    border: none !important;
} */

/* Input */

.pagination-box .pagination input {
    margin: 0 !important;
    text-align: center !important;
    outline: none !important;
}

/* .pagination-box .pagination input {
    margin: 0 !important;
    padding-top: 0.55rem !important;
    padding-bottom: 0.55rem !important;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    text-align: center !important;
    border: 0 !important;
    outline: none !important;
} */

/* Retirer les flèches des inputs number */

/* Firefox */

/* .pagination-box .pagination input[type="number"] {
    -moz-appearance: textfield;
} */

/* Chrome */

/* .pagination-box .pagination input[type="number"]::-webkit-inner-spin-button,
.pagination-box .pagination input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
} */

/* Opéra*/

/* .pagination-box .pagination input[type="number"]::-o-inner-spin-button,
.pagination-box .pagination input[type="number"]::-o-outer-spin-button { 
	-o-appearance: none;
	margin:0
} */
